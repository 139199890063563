exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-us-mdx-js-content-file-path-content-pages-contact-us-md": () => import("./../../../src/templates/ContactUsMDX.js?__contentFilePath=/opt/build/repo/content/pages/contact-us.md" /* webpackChunkName: "component---src-templates-contact-us-mdx-js-content-file-path-content-pages-contact-us-md" */),
  "component---src-templates-contact-us-mdx-js-content-file-path-content-pages-templates-contact-us-mdx-mdx": () => import("./../../../src/templates/ContactUsMDX.js?__contentFilePath=/opt/build/repo/content/pages/templates/ContactUsMDX.mdx" /* webpackChunkName: "component---src-templates-contact-us-mdx-js-content-file-path-content-pages-templates-contact-us-mdx-mdx" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-about-our-practice-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/about-our-practice.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-about-our-practice-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-before-your-visit-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/before-your-visit.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-before-your-visit-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-common-procedures-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/common-procedures.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-common-procedures-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-helpful-links-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/helpful-links.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-helpful-links-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-medical-file-upload-form-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/medical-file-upload-form.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-medical-file-upload-form-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-our-team-photos-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/our-team-photos.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-our-team-photos-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-patient-forms-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/patient-forms.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-patient-forms-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-patient-portals-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/patient-portals.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-patient-portals-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-patientinfo-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/patientinfo.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-patientinfo-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-privacy-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/privacy.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-privacy-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-published-articles-research-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/published-articles-research.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-published-articles-research-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-templates-default-page-mdx-mdx": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/templates/DefaultPageMDX.mdx" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-templates-default-page-mdx-mdx" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-what-our-patients-say-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/what-our-patients-say.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-what-our-patients-say-md" */),
  "component---src-templates-default-page-mdx-js-content-file-path-content-pages-your-first-visit-md": () => import("./../../../src/templates/DefaultPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/your-first-visit.md" /* webpackChunkName: "component---src-templates-default-page-mdx-js-content-file-path-content-pages-your-first-visit-md" */),
  "component---src-templates-doctor-detail-mdx-js-content-file-path-content-pages-doctors-john-christoforidis-md-md": () => import("./../../../src/templates/DoctorDetailMDX.js?__contentFilePath=/opt/build/repo/content/pages/doctors/john-christoforidis-md.md" /* webpackChunkName: "component---src-templates-doctor-detail-mdx-js-content-file-path-content-pages-doctors-john-christoforidis-md-md" */),
  "component---src-templates-doctor-detail-mdx-js-content-file-path-content-pages-doctors-reid-f-schindler-md": () => import("./../../../src/templates/DoctorDetailMDX.js?__contentFilePath=/opt/build/repo/content/pages/doctors/reid-f-schindler.md" /* webpackChunkName: "component---src-templates-doctor-detail-mdx-js-content-file-path-content-pages-doctors-reid-f-schindler-md" */),
  "component---src-templates-doctor-detail-mdx-js-content-file-path-content-pages-templates-doctor-detail-mdx-mdx": () => import("./../../../src/templates/DoctorDetailMDX.js?__contentFilePath=/opt/build/repo/content/pages/templates/DoctorDetailMDX.mdx" /* webpackChunkName: "component---src-templates-doctor-detail-mdx-js-content-file-path-content-pages-templates-doctor-detail-mdx-mdx" */),
  "component---src-templates-doctor-page-mdx-js-content-file-path-content-pages-doctors-section-md": () => import("./../../../src/templates/DoctorPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/doctorsSection.md" /* webpackChunkName: "component---src-templates-doctor-page-mdx-js-content-file-path-content-pages-doctors-section-md" */),
  "component---src-templates-doctor-page-mdx-js-content-file-path-content-pages-templates-doctor-page-mdx-mdx": () => import("./../../../src/templates/DoctorPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/templates/DoctorPageMDX.mdx" /* webpackChunkName: "component---src-templates-doctor-page-mdx-js-content-file-path-content-pages-templates-doctor-page-mdx-mdx" */),
  "component---src-templates-faq-page-mdx-js-content-file-path-content-pages-faq-md": () => import("./../../../src/templates/FAQPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/faq.md" /* webpackChunkName: "component---src-templates-faq-page-mdx-js-content-file-path-content-pages-faq-md" */),
  "component---src-templates-faq-page-mdx-js-content-file-path-content-pages-templates-faq-page-mdx-mdx": () => import("./../../../src/templates/FAQPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/templates/FAQPageMDX.mdx" /* webpackChunkName: "component---src-templates-faq-page-mdx-js-content-file-path-content-pages-templates-faq-page-mdx-mdx" */),
  "component---src-templates-home-page-mdx-js-content-file-path-content-pages-home-md": () => import("./../../../src/templates/HomePageMDX.js?__contentFilePath=/opt/build/repo/content/pages/home.md" /* webpackChunkName: "component---src-templates-home-page-mdx-js-content-file-path-content-pages-home-md" */),
  "component---src-templates-home-page-mdx-js-content-file-path-content-pages-templates-home-page-mdx-mdx": () => import("./../../../src/templates/HomePageMDX.js?__contentFilePath=/opt/build/repo/content/pages/templates/HomePageMDX.mdx" /* webpackChunkName: "component---src-templates-home-page-mdx-js-content-file-path-content-pages-templates-home-page-mdx-mdx" */),
  "component---src-templates-section-page-mdx-js-content-file-path-content-pages-templates-section-page-mdx-mdx": () => import("./../../../src/templates/SectionPageMDX.js?__contentFilePath=/opt/build/repo/content/pages/templates/SectionPageMDX.mdx" /* webpackChunkName: "component---src-templates-section-page-mdx-js-content-file-path-content-pages-templates-section-page-mdx-mdx" */)
}

